body {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 400;
  background: black;
}

.html-container {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

.container-fluid {
  padding: 0;
}

.navbar {
  padding-top: 0;
  padding-bottom: 0;
}

a.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar-brand img {
  height: 6rem;
  padding: 1rem;
  padding-right: 0;
}

.menu-item {
  padding: .5rem 1.3rem 0 1.3rem !important;
  line-height: 5rem;
}

.navbar-toggler {
  background-color: white;
  margin: 1rem 0;
}

.navbar-nav li:hover > .dropdown-menu {
  display: block;
}

.navbar-blackout {
  background-color: black;
}

.navbar-blackout .navbar-brand,
.navbar-blackout a.menu-item,
.navbar-blackout a.nav-item {
  color: white;
  text-decoration: none;
}

.navbar-blackout .navbar-brand {
  padding-bottom: .5rem;
}

.navbar-blackout .menu-item {
  border-bottom: .5rem solid black;
}

.navbar-blackout .menu-item:hover,
.navbar-blackout .menu-item:active,
.navbar-blackout .menu-item:focus {
  background-color: rgb(69, 69, 69);
  border-bottom: .5rem solid rgba(220,20,60,1);
}

.navbar-blackout .menu-item.active {
  border-bottom: .5rem solid rgba(220,20,60,1);
}

.dropdown-menu-dark {
  background-color: black;
}

.dropdown-menu,
.dropdown-menu[data-bs-popper] {
  min-width: 100%;
  padding: 0;
  background-color: white;
  border: none;
  border-radius: 0;
}

.dropdown-menu a.dropdown-item {
  padding: .5rem;
  color: black;
  border-left: .5rem solid white;
}

.dropdown-menu a.dropdown-item.active {
  color: black;
  background-color: rgb(224, 224, 224);
  border-left: .5rem solid rgba(220,20,60,1);
}


.dropdown-menu a.dropdown-item:hover,
.dropdown-menu a.dropdown-item:active,
.dropdown-menu a.dropdown-item:focus {
  color: black;
  background-color: rgb(224, 224, 224);
  border-left: .5rem solid rgba(220,20,60,1);
}

@media only screen 
and (max-width : 991px) {
  .navbar-collapse {
    padding-bottom: 1.3rem;
    border-bottom: .5rem solid white;
  }
}

.main-container {
  padding-top: 3rem;
}

footer {
  margin: 0 !important;
  min-height: 6em;
  color: white;
  line-height: 1.8;
  background: linear-gradient(180deg, rgba(220,20,60,1) 0%, rgba(128,0,255,1) 100%);
  user-select: none;
}

.error {
  color: white;
  background-color: black;
  margin: 0;
  padding: 1rem;
}