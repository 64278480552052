.spinner {
    color: white;
    min-height: 6rem;
    min-width: 6rem;
    background-color: black;
    margin-top: 4rem;
    animation: spin infinite 13s linear;
  }

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
