.main_site_bg {
    background-color: black;
}

.main-container a {
    color: white;
    text-decoration: none;
}

.main-container a.project_item h3 {
    margin-bottom: .8rem;
}

.main-container a.project_item:hover {
    text-decoration: underline;
    text-decoration-color: rgba(220,20,60,1);
    text-decoration-thickness: .3rem;
    text-underline-offset: .5rem;
}

.main-container a.project_item img {
    user-select: none;
    pointer-events: all !important;
}

.project-image {
    border-radius: 13%;
}

@media only screen 
and (max-width : 991px) {
    .main-container a.project_item img {
        width: 60%;
        margin: 0 20%;
        user-select: none;
        pointer-events: all !important;
    }
}

@media only screen 
and (min-width : 992px) {
    .main-container a.project_item img {
        width: 30%;
        margin: 0 35%;
    }
}