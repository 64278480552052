.container-fluid .idays {
    background-color: white;
}

.idays h1,
.idays h4 {
    color: black;
    margin-bottom: 1.69rem;
    user-select: none;
}

.idays a {
    color: black;
}

.navbar-yellow {
    color: black;
    background-color: #ffdb3d;
    min-height: 6rem;
    margin-bottom: 2rem;
}

.navbar-yellow h3 {
    color: black;
    vertical-align: middle;
    user-select: none;
}

.navbar-yellow span {
    margin: 1.69rem 0;
}

.navbar-yellow span img {
    margin-right: 1rem;
    vertical-align: middle;
    user-select: none;
    pointer-events: none;
}

.navbar-yellow h3.d-inline-block {
    margin: 0;
}

.idays #floatingTextarea {
    min-height: 0;
}