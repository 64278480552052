.main_site_bg {
    background-color: black;
}

.main-container .app-img {
    width: 100%;
    user-select: none;
    pointer-events: none;
}

.main-container h2,
.main-container p,
.main-container ul {
    color: white;
    user-select: none;
    pointer-events: none;
}

.main-container h2 {
    margin-bottom: 3rem;
}

.container .row div p {
    padding-top: .3rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.icon {
    display: inline-flex;
    align-self: center;
    margin-right: .6em;
}

.icon svg, .icon img {
    height: 1em;
    width: 1em;
    fill: currentColor;
}

.icon.baseline svg, .icon img {
    top: .125em;
    position: relative;
}

.btns .btn {
    margin: 1rem 0;
}

.btns {
    margin-top: 1rem;
    margin-bottom: 3.3rem;
}

.project-image {
    border-radius: 13%;
}