.main_site_bg {
    background-color: black;
}

.main-container h1,
.main-container h4 {
    color: white;
    user-select: none;
}

.main-container h1 {
    line-height: 1.8;
    padding-bottom: .5em;
}

.main-container h4 {
    padding-bottom: 1.3em;
}

.main-container h1 a {
    text-decoration: underline white;
}

.main-container h1 a:hover {
    text-decoration: underline rgba(220,20,60,1) solid;
    text-decoration-thickness: .3rem;
}

.btn {
    margin-bottom: 3.3rem;
}