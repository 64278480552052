.policy-view {
    color: black;
    background-color: white;
    padding: 1.5rem 3rem 4rem 3rem;
}

.policy-title {
    color: white;
    margin-bottom: 3rem;
}

.policy-markdown h1,
.policy-markdown h2,
.policy-markdown h3,
.policy-markdown h4,
.policy-markdown h5,
.policy-markdown h6,
.policy-markdown p,
.policy-markdown a,
.policy-markdown ul,
.policy-markdown li {
    color: black !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.policy-markdown h1,
.policy-markdown h2,
.policy-markdown h3,
.policy-markdown h4,
.policy-markdown h5,
.policy-markdown h6 {
    padding: 1rem 0 !important;
}

.policy-markdown p {
    padding-bottom: .25rem !important;
}

.policy-markdown a {
    text-decoration: underline;
    user-select: all;
    pointer-events: all;
}