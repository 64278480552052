.main_site_bg {
    background-color: black;
}

.main-container h1 {
    color: white;
    padding-bottom: 1.3rem;
}

.main-container .error {
    color: white;
}

form div {
    padding-bottom: 1.3rem;
}

.btn {
    margin-bottom: 3.3rem;
}

#floatingTextarea {
    min-height: 100px;
}

#floatingTextarea label {
    vertical-align: text-top;
}